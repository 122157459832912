// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css';

import 'vuetify/styles';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { fa } from 'vuetify/iconsets/fa-svg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faDiscord, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { VSnackbarQueue } from 'vuetify/labs/components';
import { da } from 'vuetify/locale'

library.add(faDiscord, faTiktok)
const DashboardTheme: ThemeDefinition = {
	dark: true,
	colors: {
		background: '#111827',
		surface: '#111827',
	},
}


const FrontTheme: ThemeDefinition = {
	dark: true,
	colors: {
		background: '#111827',
	},
}

export default defineNuxtPlugin((app) => {
  	app.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
  	const vuetify = createVuetify({
		components: {
			VSnackbarQueue
		},
		locale: {
			locale: "da",
			fallback: "en",
			messages: { da }
		},
		theme: {
			defaultTheme: 'dark',
			themes: {
				DashboardTheme,
				FrontTheme
			},
		},
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				mdi,
				fa
			},
		},
		ssr: true,
	});
	app.vueApp.use(vuetify);
});
