<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-img
    :src="useImage()('/img/parallax.png', { format: 'webp', quality: 100 })"
    gradient="to top right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)"
    cover
    height="100vh"
    alt="MineClub"
  >
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4 text-red">
        <v-icon x-large :icon="mdiMinecraft"/>
        MineClub
      </h1>
      <h2 class="subheading text-red">
        {{ error?.statusCode === 404 ? 'Siden blev ikke fundet' : 'Der skete en fejl' }}
      </h2>
      <br >
      <NuxtLink to="/"><v-btn>Gå til forsiden</v-btn></NuxtLink>
    </div>
  </v-img>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import { mdiMinecraft } from '@mdi/js';
import { useImage } from '#imports';

defineProps<{ error: NuxtError }>();
useHead({
  title: 'MineClub',
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
  ],
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'MineClub - Kommer snart!',
    },
  ],
  htmlAttrs: {
    lang: 'da-DK',
  },
});
</script>
